import {PROGRAM_NAMES_ENUM} from "src/constants/workflow-instance";

export const PROGRAM_BUSINESS_NAME: Record<PROGRAM_NAMES_ENUM, string> = {
    [PROGRAM_NAMES_ENUM.ACBP]: "COBB(SDO)",
    [PROGRAM_NAMES_ENUM.OTS]: "EBHS(OTS)",
    [PROGRAM_NAMES_ENUM.TEST]: "TEST",
    [PROGRAM_NAMES_ENUM.SIC]: "EBHS(SIC)",
    [PROGRAM_NAMES_ENUM.DC]: "EBHS(DC)",
    [PROGRAM_NAMES_ENUM.BIC]: "EBHS(BIC)",
    [PROGRAM_NAMES_ENUM.BrandLifecycle]: "BrandLifecycle",
    [PROGRAM_NAMES_ENUM.SelectionGapManagement]: "Selection Gap Management",
    [PROGRAM_NAMES_ENUM.DTP]: "COBB(DTP)",
    [PROGRAM_NAMES_ENUM.EstimatedParity]: "Estimated Parity",
    [PROGRAM_NAMES_ENUM.WebsiteInsights]: "Website Insights",
    [PROGRAM_NAMES_ENUM.IntegTest]: "IntegTest",
    [PROGRAM_NAMES_ENUM.StressTest]: "StressTest",
    [PROGRAM_NAMES_ENUM.ProductCompatibilityProgram]: "PCP",
    [PROGRAM_NAMES_ENUM.UMS]: "UMS",
    [PROGRAM_NAMES_ENUM.Matching]: "Matching",
    [PROGRAM_NAMES_ENUM.SACE_SCI]: "EBHS(SCI)",
    [PROGRAM_NAMES_ENUM.BrandClassifier]: "BrandClassifier",
    [PROGRAM_NAMES_ENUM.GEM]: "GEM",
    [PROGRAM_NAMES_ENUM.DataStageIntegrationTest]: "DataStageIntegrationTest",
    [PROGRAM_NAMES_ENUM.BrandArtefactEnrichment]: "BrandArtefactEnrichment",
    [PROGRAM_NAMES_ENUM.SCI]: "SCI",
    [PROGRAM_NAMES_ENUM.SCIAudit]: "SCIAudit",
    [PROGRAM_NAMES_ENUM.AuditBatch]: "AuditBatch",
    [PROGRAM_NAMES_ENUM.TestAppConfig]: "TestAppConfig",
    [PROGRAM_NAMES_ENUM.UBCSnapshotConsumption]: "UBCSnapshotConsumption",
    [PROGRAM_NAMES_ENUM.SFASINEnrichment]: "SF_ASIN_Enrichment",
    [PROGRAM_NAMES_ENUM.OASIS]: "OASIS",
    [PROGRAM_NAMES_ENUM.SACE_SCI_3P]: "EBHS(SCI)-3P",
    [PROGRAM_NAMES_ENUM.EBHSDigitization]: "EBHSDigitization",
    [PROGRAM_NAMES_ENUM.EDRI_Starfish]: "EDRI_Starfish",
    [PROGRAM_NAMES_ENUM.Starfish]: "Starfish",
    [PROGRAM_NAMES_ENUM.DataQuality]: "DataQuality",
    [PROGRAM_NAMES_ENUM.EmBHSDigitization]: "EmBHSDigitization"
}

export const PROGRAM_PAGE_ZERO_VISIBILITY: Record<PROGRAM_NAMES_ENUM, boolean> = {
    [PROGRAM_NAMES_ENUM.ACBP]: true,
    [PROGRAM_NAMES_ENUM.OTS]: true,
    [PROGRAM_NAMES_ENUM.TEST]: false,
    [PROGRAM_NAMES_ENUM.SIC]: true,
    [PROGRAM_NAMES_ENUM.DC]: true,
    [PROGRAM_NAMES_ENUM.BIC]: true,
    [PROGRAM_NAMES_ENUM.BrandLifecycle]: true,
    [PROGRAM_NAMES_ENUM.SelectionGapManagement]: true,
    [PROGRAM_NAMES_ENUM.DTP]: true,
    [PROGRAM_NAMES_ENUM.EstimatedParity]: true,
    [PROGRAM_NAMES_ENUM.WebsiteInsights]: true,
    [PROGRAM_NAMES_ENUM.IntegTest]: false,
    [PROGRAM_NAMES_ENUM.ProductCompatibilityProgram]: true,
    [PROGRAM_NAMES_ENUM.UMS]: false,
    [PROGRAM_NAMES_ENUM.Matching]: false,
    [PROGRAM_NAMES_ENUM.SACE_SCI]: true,
    [PROGRAM_NAMES_ENUM.BrandClassifier]: false,
    [PROGRAM_NAMES_ENUM.GEM]: false,
    [PROGRAM_NAMES_ENUM.DataStageIntegrationTest]: false,
    [PROGRAM_NAMES_ENUM.BrandArtefactEnrichment]: false,
    [PROGRAM_NAMES_ENUM.SCI]: true,
    [PROGRAM_NAMES_ENUM.SCIAudit]: false,
    [PROGRAM_NAMES_ENUM.AuditBatch]: false,
    [PROGRAM_NAMES_ENUM.TestAppConfig]: false,
    [PROGRAM_NAMES_ENUM.UBCSnapshotConsumption]: false,
    [PROGRAM_NAMES_ENUM.SFASINEnrichment]: true,
    [PROGRAM_NAMES_ENUM.OASIS]: true,
    [PROGRAM_NAMES_ENUM.SACE_SCI_3P]: true,
    [PROGRAM_NAMES_ENUM.EBHSDigitization]: true,
    [PROGRAM_NAMES_ENUM.StressTest]: false,
    [PROGRAM_NAMES_ENUM.EDRI_Starfish]: true,
    [PROGRAM_NAMES_ENUM.Starfish]: true,
    [PROGRAM_NAMES_ENUM.DataQuality]: true,
    [PROGRAM_NAMES_ENUM.EmBHSDigitization]: true
}

export const EXCLUDE_CYCLE_TIME_DATA_FOR_PROGRAMS = [PROGRAM_NAMES_ENUM.SIC, PROGRAM_NAMES_ENUM.DC, PROGRAM_NAMES_ENUM.BIC]
